<template>
  <div>
    <div v-if="tenant.id == 'eventscom'">
      <h1>We'd love to hear from you!</h1>
      <p>
        EveryChallenge is a Virtual Challenge Platform focused on creating engaging virtual endurance sports experiences. This tool was created to provide a responsible way for participants to enjoy the great outdoors, stay active, and connect. 
      </p>
      <p>
        <v-btn outlined color="primary" to="/about">More about us</v-btn>
      </p>

      <v-alert type="info">
        <strong>Is your question about an event or challenge?</strong>
        Please email directly to the organization hosting your event for faster response times!
      </v-alert>
      <h3>Contact Us</h3>
      <p>Email us at <a href="mailto:hello@events.com">hello@events.com</a> and we'll get back to you ASAP.</p>

    </div>
    <div v-else>
      <h1>We'd love to hear from you!</h1>
      <v-alert type="info">
        <strong>Is your question about an event or challenge?</strong>
        Please email directly to the organization hosting your event for faster response times!
      </v-alert>
      <p>
        Are you interested in hosting an in-person, hybrid or virtual event? Leave us a note and we'll get back to you shortly on how we can help you succeed.
      </p>
      <p>
        Our focus is to create the best experience for both event organizers and participants of events. We want to make it easy to host connected and engaging events so your participants can have a fun time and will come back for the next edition.
      </p>
      <p>
        <v-btn outlined color="primary" to="/about">More about us</v-btn>
      </p>


      <h3>Contact Us</h3>
      <p>Leave a message using form below, or simply email us at <a href="mailto:hello@sodisp.com">hello@sodisp.com</a>.</p>

  <!--     <script type="application/javascript" src='https://noctilucentbv.myfreshworks.com/crm/sales/web_forms/096908605eb5eabd275b43e48d9229ebeb4689f9552bb3176aae585aeea70943/form.js' crossorigin='anonymous' id='fs_096908605eb5eabd275b43e48d9229ebeb4689f9552bb3176aae585aeea70943'></script> -->

  <!-- zoho

      <v-form lazy-validation ref="form" name=WebToLeads311694000000341011 action="https://crm.zoho.eu/crm/WebToLeadForm" method="POST">
        <input type='hidden' name='xnQsjsdp' value='529d92437bb2ad17c3fe95805d769379b5d1d7b2d99a331c2cea4fed8dd78579'/>
        <input type='hidden' name='zc_gad' id='zc_gad' value=''/>
        <input type='hidden' name='xmIwtLD' value='d3792969e786d2a3c7b3a3f42e79e9077850c59d203cb42d05914a0b5bf86f0a'/>
        <input type='hidden' name='actionType' value='TGVhZHM='/>
        <input type='hidden' name='returnURL' value='https://sodisp.com/thankyou' />

        <v-text-field
          v-model="name"
          :rules="nameRules"
          label="Your Name"
          id="Last_Name"
          name="Last Name"
          required
        ></v-text-field>

        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          name="Email"
          id="Email"
          type="Email"
          required
        ></v-text-field>

        <v-textarea v-model="comments" required label="Your message:" name="Description" id="Description"></v-textarea>
      
        <v-btn class="primary" type="submit">Submit</v-btn>
      </v-form> 

      <hr/>
  -->

      <v-form lazy-validation ref="form" action="https://formspree.io/mlepzddz" method="POST" @submit="submit">

        <v-text-field
          v-model="name"
          :rules="nameRules"
          label="Your Name"
          name="name"
          required
        ></v-text-field>

        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          name="_replyto"
          type="email"
          required
        ></v-text-field>

        <v-textarea v-model="comments" required label="Your message:" name="comments"></v-textarea>
        <input type="hidden" name="timezone" :value="timeZoneOlson"/>
      
        <v-btn class="primary" type="submit">Submit</v-btn>
      </v-form> 

      </div>
  </div>
</template>


<script>
import { DateTime } from 'luxon'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Contact",
  props: {
  },
  data: function() {
    return {
      tenant: tenant,
      valid: true,
      timeZoneOlson: null,
      name: '',
      nameRules: [
        v => !!v || 'Name is required',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      comments: ''
    };
  },
  created() {
  },
  async mounted() {
    //this.track();
    this.name = this.$route.query.name;
    this.email = this.$route.query.email;
    this.comments = this.$route.query.comments;
    this.timeZoneOlson = DateTime.now().zoneName;

    if (this.email) {
      console.log('Scrolling to form');
      await this.$nextTick();
      this.$refs.form.$el.scrollIntoView();
    }
  },
  methods: {
    submit () {
      this.$analytics.event('contact_submit');
    },
    track () {

      //console.log('Tracking event', this.$analytics, 'includes', window.enableAnalyticsProperty);
      //window.disableAnalyticsProperty('UA-163164505-2');
      //window.enableAnalyticsProperty('UA-163164505-2');
      //this.$analytics.query('config', 'UA-163164505-2', {'send_page_view': false});
      //this.$analytics.enableCustomTrackers(this, [ 'UA-163164505-2' ]);
      //this.$analytics.event('test-1234');
      //this.$analytics.disableCustomTrackers(this);
    }
  },
  watch: {
  }
};
</script>

<style scoped>
</style>